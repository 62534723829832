import * as React from 'react';
import './styles.scss';

interface Props {
  name: string;
  code: string;
  description: string;
}

const ClassItem: React.FunctionComponent<Props> = ({ name, code, description }) => {
  return (
    <div
      className="class-item"
    >
      <h2>{name}</h2>
      <div className="class-item__code">
        <p>{code}</p>
      </div>
      <div className="class-item__description">
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ClassItem;
