import { graphql } from 'gatsby';
import * as React from 'react';
import ClassItem from '../components/class-item/component';
import Layout from '../components/layout/component';

interface ClassesPageProps {
  data: {
    allClassesJson: {
      edges: ClassNode[];
    },
  };
}

interface ClassNode {
  node: {
    slug: string;
    name: string;
    description: string;
  };
}

interface Class {
  name: string;
  shortCode: string;
  description: string;
}

export const classesPageQuery = graphql`
  query SiteQuery {
    allClassesJson {
      edges {
        node {
          slug
          name
          description
        }
      }
    }
  }
`;

const ClassesPage: React.FunctionComponent<ClassesPageProps> = ({data}) => {
  const classes: Class[] = data.allClassesJson.edges.map((classNode: ClassNode) => {
    return {
      description: classNode.node.description,
      name: classNode.node.name,
      shortCode: classNode.node.slug,
    };
  });

  const classTabTitle = 'Classes';
  const classPageTitle = 'LDT Classes';
  const classPageDescription = 'These are the classes I took during the Learning, Design, and Technology Masters program at Stanford University (2018-2019).*';
  const classPageFootnote = (
    <div>
      *Class descriptions taken from {` `}
      <a href="https://explorecourses.stanford.edu/">Stanford Course Lookup</a>
    </div>
  );

  return (
    <Layout
      tabTitle={classTabTitle}
      title={classPageTitle}
      description={classPageDescription}
      footnote={classPageFootnote}
    >
      {getClassesList(classes)}
    </Layout>
  );
};

function getClassesList(classes: Class[]): JSX.Element[] {
  const classObjects = classes.map((c: Class) => {
    return (
      <ClassItem
        key={c.shortCode}
        name={c.name}
        code={c.shortCode}
        description={c.description}
      />
    );
  });
  return classObjects;
}

export default ClassesPage;
